<template>
    <b-container v-if="mpcForm" fluid>
        <b-row>
            <b-col cols="12">
                <h2 class="text-center my-4">Uredi MPC obrazac</h2>
                <b-col md="12" class="flex-center">
                    <b-form v-on:submit.prevent="updateForm">
                        <b-form-group :label-cols="4"
                                      breakpoint="md"
                                      label="Izaberite dobavljača:"
                                      label-for="associate">
                            <select v-if="associates" v-model="mpcForm.associate.id" id="associate" name="associate_id" class="form-control form-control-sm custom-select custom-select-sm">
                                <option value="" selected disabled>-- Molimo odaberite dobavljača --</option>
                                <option v-for="associate in associates.suppliers" :value="associate.id">{{associate.name}}</option>
                            </select>
                        </b-form-group>
                        <b-form-group :label-cols="4"
                                      breakpoint="md"
                                      label="Broj računa:"
                                      label-for="invoice_number">
                            <b-form-input :value="mpcForm.invoice_number"
                                          id="invoice_number"
                                          name="invoice_number"
                                          required
                                          type="text"
                                          size="sm"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group :label-cols="4"
                                      breakpoint="md"
                                      label="Datum računa:"
                                      label-for="invoice_date">
                            <date-picker format="DD.MM.YYYY"
                                         v-model="mpcForm.invoice_date"
                                         :lang="$parent.hrv"
                                         :first-day-of-week="1"
                                         class="w-100"
                                         :input-attr="{required: true}"
                                         id="invoice_date">
                            </date-picker>
                        </b-form-group>
                        <b-form-group :label-cols="4"
                                      breakpoint="md"
                                      label="Broj obrasca:"
                                      label-for="form_number">
                            <b-form-input :value="mpcForm.form_number" id="form_number" name="form_number" required
                                          type="text"
                                          size="sm"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group :label-cols="4"
                                      breakpoint="md"
                                      label="Datum obrasca:"
                                      label-for="form_date">
                            <date-picker format="DD.MM.YYYY"
                                         v-model="mpcForm.form_date"
                                         :lang="$parent.hrv"
                                         :first-day-of-week="1"
                                         class="w-100"
                                         :input-attr="{required: true}"
                                         id="form_date">
                            </date-picker>
                        </b-form-group>
                        <b-form-group :label-cols-md="4">
                            <b-form-radio-group
                                    id="variant"
                                    name="variant"
                                    v-model="mpcForm.variant">
                                <b-form-radio value="0">Prijemni list</b-form-radio>
                                <b-form-radio value="1">Povratnica</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-btn variant="primary" type="submit" class="pull-right">Potvrdi</b-btn>
                    </b-form>
                </b-col>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'EditMpcForm',
        data() {
            return {
                mpcForm: null
            }
        },
        computed: {
            associates: function () {
                return this.$store.getters.associates;
            }
        },
        methods: {
            updateForm: function (e) {
                const formData = new FormData(e.target);
                formData.append('invoice_date', this.$moment(this.mpcForm.invoice_date).format("YYYY-MM-DD"));
                formData.append('form_date', this.$moment(this.mpcForm.form_date).format("YYYY-MM-DD"));
                axiOsta.post(api.mpcForms + this.$route.params.id, formData).then(response => {
                    if(response.status === 200) {
                        this.$router.push('/mpc_forms/' + response.data.id);
                    }
                });
            }
        },
        created() {
            axiOsta.get(api.mpcForms + this.$route.params.id).then(response => {
                if (response.status === 200) {
                    this.mpcForm = response.data;
                }
            });
        }
    }
</script>
